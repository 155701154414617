/* eslint-disable */
import * as React from 'react'

import './DivineFelines.css'

import { inputLabelClasses } from '@mui/material/InputLabel'
import { green } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { TextField } from '@mui/material'
import * as EmailValidator from 'email-validator'

import linkTreeLogo from './img/linktree-logo.png'
import twitterLogo from './img/twitter-logo.png'
import instagramLogo from './img/instagram-logo.png'
import IARLogo from './img/iar-logo-white.svg'
import EthLogo from './img/vip badge.png'

import geneziaImage from './img/Genezia.png'
import infurnoImage from './img/Infurno_no-bg.png'
import purrseidonImage from './img/Purrseidon_no-bg.png'
import cleocatraImage from './img/Cleocatra_no-bg.png'
import mintPlaceholder from './img/Denizens.png'

import NFTDrop from './img/nft-drop.png'
import ethDogtags from './img/eth-dogtags.png'
import clockTransparent from './img/clock.png'

import tridentCrown from './img/Trident_Crown.png'

import genesisRulers from './genesis_rulers.json'

const headerYellow = '#FADA68'
const paleYellow = 'rgb(251,251,181)'
const fontPhosphateSolid = 'Phosphate-Solid'
const fontPhosphateSolidButton = 'Phosphate-Solid-Button'
const fontPhosphate = 'Phosphate'

const NUM_MINT = 300

type CAT_NAMES = 'purrseidon' | 'cleocatra' | 'infurno'

interface Accessory {
  name: string
  isExclusive: boolean
}

const catCards: Record<
  CAT_NAMES,
  {
    description: string
    imageSrc: string
    background: string
    accessories: Accessory[]
  }
> = {
  purrseidon: {
    description: genesisRulers.purrseidon.description,
    imageSrc: purrseidonImage,
    background: genesisRulers.purrseidon.background,
    accessories: genesisRulers.purrseidon.accessories
  },
  infurno: {
    description: genesisRulers.infurno.description,
    imageSrc: infurnoImage,
    background: genesisRulers.infurno.background,
    accessories: genesisRulers.infurno.accessories
  },
  cleocatra: {
    description: genesisRulers.cleocatra.description,
    imageSrc: cleocatraImage,
    background: genesisRulers.cleocatra.background,
    accessories: genesisRulers.cleocatra.accessories
  }
}

const styles = {
  benefitsIcon: {
    maxWidth: '100%',
    maxHeight: '200px'
  },
  lightBlueBackgroundFlat: {
    background: '#35657D',
    textAlign: 'center' as const
  },
  lightBlueBackgroundSection: {
    background: 'linear-gradient(rgb(34,75,100), rgb(54,101,129))',
    textAlign: 'center' as const
  },
  darkBlueBackgroundSection: {
    background: 'linear-gradient(rgb(30,60,92), rgb(26,46,85))',
    color: 'white',
    padding: '20px',
    textAlign: 'center' as const
  },
  flatDarkBlueBackgroundSection: {
    background: '#213563',
    color: 'white',
    padding: '20px',
    textAlign: 'center' as const
  },
  purpleBackgroundSection: {
    background:
      'linear-gradient(0.85deg, #3F3874 4.47%, #3F3874 74.12%, #3C3286 96.37%)',
    textAlign: 'center' as const,
    padding: '20px'
  },
  benefitsItem: {
    maxWidth: '300px'
  },
  yellowButton: {
    color: '#000',
    fontFamily: fontPhosphateSolidButton,
    backgroundColor: headerYellow,
    padding: '15px',
    paddingBottom: '5px',
    display: 'inline-block',
    textDecoration: 'none',
    textTransform: 'uppercase' as const
  }
}

const purple = '#3e2e74'

const theme = createTheme({
  palette: {
    primary: {
      main: purple
    },
    secondary: {
      main: green[500]
    },
    text: {
      secondary: '#FFFFFF'
    }
  }
})

const TitleImageSection = () => (
  <section
    className='hero-section'
    style={{
      ...styles.darkBlueBackgroundSection,
      paddingLeft: 0,
      paddingRight: 0
    }}
  >
    <div className='section-content text-only' style={{ paddingBottom: 0 }}>
      <Typography
        className='h1'
        variant='h1'
        align='center'
        color={headerYellow}
        fontFamily={fontPhosphate}
        style={{ paddingTop: '20px', textTransform: 'uppercase' }}
      >
        Divine Felines
      </Typography>
    </div>
    <div
      className='section-content text-only'
      style={{ paddingTop: 0, paddingLeft: '20px', paddingRight: '20px' }}
    >
      <p>
        Divine Felines is the first of a series of projects to use NFT ownership
        for social good.
      </p>
      <p>
        Join us! Find your Spirit Feline, collaborate on sick art projects,
        build value, and help us contribute to animal welfare.
      </p>{' '}
      <a
        href='https://opensea.io/collection/divine-felines-rulers-of-genezia'
        target='_blank'
        style={{ ...styles.yellowButton, marginTop: '1em' }}
      >
        Early Access on OpenSea
      </a>
      <br />
      <a
        href='#sign-up-form'
        style={{ ...styles.yellowButton, marginTop: '1em' }}
      >
        Follow us
      </a>
    </div>
    <img
      src={geneziaImage}
      alt='The planet Genezia'
      style={{
        marginTop: '40px',
        width: '100%',
        maxWidth: '800px',
        paddingLeft: '0px',
        paddingRight: '0px'
      }}
    />
  </section>
)

const DivineMetaverseIntroSection = () => (
  <section style={styles.purpleBackgroundSection}>
    <div className='section-content text-only'>
      <Typography
        variant='h5'
        align='center'
        fontSize='2em'
        paragraph
        color={headerYellow}
        fontFamily={fontPhosphateSolid}
        textAlign={'center'}
        style={{ textTransform: 'uppercase' }}
      >
        Welcome to the divine Metaverse
      </Typography>
      <p>
        In an age long after humankind's demise, those that once believed
        themselves the Earth’s supreme beings, found themselves having taken
        their rightful place as rulers of the galaxy. The age of the felines, or
        the Felicene, as it came to be called by prominent catedemics of the
        time, was an era of peace and prosperity for all things four-legged and
        furry.
      </p>
      <p>
        The first phase of the Divine Felines art project brings the planet
        Genezia and its inhabitants to life. The Rulers of Genezia are available
        for purchase on OpenSea now. The planet's {NUM_MINT} inhabitants, the
        Tribes of Genezia, will be available to mint shortly!{' '}
      </p>
    </div>
  </section>
)

const AccessoriesList = ({ accessories }: { accessories: Accessory[] }) => (
  <div style={{ marginTop: '3em' }}>
    <Typography
      className='h5'
      variant='h5'
      align='left'
      style={{ fontSize: '1em', marginBottom: 0 }}
      color={paleYellow}
      paragraph
      fontFamily={fontPhosphateSolid}
    >
      Accessories
    </Typography>
    <div className='flex-row' style={{ justifyContent: 'flex-start' }}>
      {accessories.map(a => (
        <div
          key={a.name}
          style={{
            flexGrow: 0,
            flexShrink: 0,
            marginRight: '1.5em',
            padding: '0.5em',
            paddingLeft: 0
          }}
        >
          <span>{a.name}</span>
          {a.isExclusive && (
            <div
              style={{
                marginLeft: '0.5em',
                borderRadius: '1px',
                paddingTop: '8px',
                display: 'inline-block',
                fontSize: '12px',
                background: '#D49B2C',
                paddingLeft: '0.5em',
                paddingRight: '0.5em',
                fontFamily: fontPhosphateSolidButton
              }}
            >
              EXCLUSIVE
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
)

const CatBioCard = ({ title }: { title: keyof typeof catCards }) => (
  <div
    id={title}
    className='cat-card'
    style={{
      background: catCards[title].background
    }}
  >
    <div className='flex-row'>
      <div style={{ marginLeft: 'auto' }}>
        <div>
          <img
            className='cat-image'
            alt={title}
            src={catCards[title].imageSrc}
          />
        </div>
      </div>
      <div
        className='flex-double-column'
        style={{
          padding: '2em',
          textAlign: 'left'
        }}
      >
        <div>
          <Typography
            className='h5 cat-card-title'
            variant='h5'
            align='left'
            style={{ fontSize: '2em', marginBottom: 0 }}
            color={paleYellow}
            paragraph
            fontFamily={fontPhosphateSolid}
          >
            {title}
          </Typography>
          <p
            style={{
              fontSize: '1em',
              marginTop: 0,
              fontFamily: 'Lineto-circular-book'
            }}
          >
            {catCards[title].description}
          </p>
          <AccessoriesList accessories={catCards[title].accessories} />
        </div>
      </div>
    </div>
  </div>
)

const HeaderSubheader = ({
  header,
  subheader
}: {
  header: string
  subheader: string
}) => (
  <>
    <Typography
      variant='h5'
      align='center'
      color={headerYellow}
      paragraph
      fontSize='2em'
      fontFamily={fontPhosphateSolid}
    >
      {header}
    </Typography>
    <Typography
      className='h5'
      variant='h5'
      align='center'
      style={{ fontSize: '3em' }}
      color={paleYellow}
      paragraph
      fontFamily={fontPhosphateSolid}
    >
      {subheader}
    </Typography>
  </>
)

const EarlyAccessSection = () => (
  <section
    id='early-access'
    style={{
      background: 'linear-gradient(rgb(30,60,92), rgb(26,46,85))',
      color: 'white',
      textAlign: 'center'
    }}
  >
    <div className='section-content text-only'>
      <HeaderSubheader
        header='Early Access'
        subheader='The Rulers of Genezia'
      />
      <p>
        The Rulers of Genezia, a set of three hand-drawn 1/1 collectibles, is
        available for early access purchase. Owners of any of the three Rulers
        will be entitled to early mint access, NFT giveaways and more.
      </p>
      <a
        href='https://opensea.io/collection/divine-felines-rulers-of-genezia'
        style={{ ...styles.yellowButton, marginTop: '1em' }}
        target='_blank'
      >
        Buy now on OpenSea
      </a>
    </div>
    <div className='cat-cards-wrapper'>
      <CatBioCard title='infurno'></CatBioCard>
      <CatBioCard title='purrseidon'></CatBioCard>
      <CatBioCard title='cleocatra'></CatBioCard>
    </div>
    <div className='section-content mobile-wide' id='early-access-benefits'>
      <div style={{ marginTop: '40px' }}>
        <Typography
          style={{
            color: headerYellow,
            marginTop: '0',
            marginBottom: '2em',
            fontSize: '24px',
            fontFamily: fontPhosphateSolid,
            textTransform: 'uppercase'
          }}
        >
          Benefits of early access
        </Typography>
      </div>
      <div className='flex-row' style={{ justifyContent: 'space-around' }}>
        <div className='flex-column'>
          <div>
            <img style={styles.benefitsIcon} src={clockTransparent} />
            <Typography variant='h5' fontFamily={fontPhosphate}>
              Early minting
            </Typography>
            <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
              <p>
                The first five owners of each of the Early Access collection
                will be whitelisted for access to the mint days before public
                release.
              </p>
            </div>
          </div>
        </div>
        <div className='flex-column'>
          <div>
            <img style={styles.benefitsIcon} src={NFTDrop} />
            <Typography variant='h5' fontFamily={fontPhosphate}>
              Free NFT Airdrop
            </Typography>
            <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
              <p>
                The first owner of each of the Early Access collection will get
                a free airdrop of a Divine Felines NFT, directly from the core
                team, once the mint begins.
              </p>
            </div>
          </div>
        </div>
        <div className='flex-column'>
          <div>
            <img style={styles.benefitsIcon} src={EthLogo} />
            <Typography variant='h5' fontFamily={fontPhosphate}>
              Exclusive privileges
            </Typography>
            <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
              <p>
                All owners of Early Access collection NFTs will be eligible for
                exclusive perks on this and all future projects by the Divine
                Felines team.
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        href='https://opensea.io/collection/divine-felines-rulers-of-genezia'
        target='_blank'
        style={{
          ...styles.yellowButton,
          marginTop: '3em',
          marginBottom: '3em'
        }}
      >
        Buy now on OpenSea
      </a>
    </div>
  </section>
)

const MintPreviewSection = () => (
  <>
    <section style={styles.darkBlueBackgroundSection} id='tribes-of-genezia'>
      <div className='section-content text-only'>
        <HeaderSubheader
          header='coming soon'
          subheader='The Tribes of Genezia'
        />
        <p>
          There's a cat for everyone. Find your Spirit Cat in the our first ever
          mint event, the Tribes of Genezia!
        </p>
        <p>
          {NUM_MINT} unique Divine Felines artworks will be available for mint
          via a verified smart contract. All NFTs in the Tribes of Genezia mint
          will be available to mint at a flat rate of 0.05ETH.
        </p>
      </div>
      <div className='section-content mobile-wide'>
        <div>
          <img
            src={mintPlaceholder}
            style={{ maxWidth: '100%', maxHeight: '300px' }}
          />
          <p style={{ fontSize: '1rem' }}>
            Actual images will be revealed during the mint
          </p>
          <div className='flex-row' style={{ marginTop: '40px' }}>
            <div className='flex-column'>
              <div className='mint-details-column'>
                <img
                  src={ethDogtags}
                  style={{ height: '200px', marginBottom: '30px' }}
                />
                <Typography variant='h5' fontFamily={fontPhosphate}>
                  Ethereum drops
                </Typography>
                <p>
                  The mint will contain an undisclosed number of exclusive
                  'Guardians of Planet Genezia' NFTs, randomly placed in the
                  mint set. If you're lucky enough to own one of these coveted
                  cats (or should we say, if one of them owns you) at the time
                  the mint ends, we'll airdop you some ETH!
                </p>
              </div>
            </div>
            <div className='flex-column'>
              <div className='mint-details-column'>
                <img
                  src={IARLogo}
                  style={{ height: '200px', marginBottom: '30px' }}
                />
                <Typography variant='h5' fontFamily={fontPhosphate}>
                  Animal welfare
                </Typography>
                <p>
                  We commit to donate 10% of the proceeds from the Tribes of
                  Genezia mint to{' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      color: 'rgb(250, 218, 104)'
                    }}
                    href='https://www.internationalanimalrescue.org/'
                  >
                    International Animal Rescue
                  </a>
                  . We'll upload an NFT of our donation receipts once the mint
                  is complete.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

const BulletPoint = ({
  children
}: {
  children: JSX.Element | JSX.Element[]
}) => (
  <div className='flex-row' style={{ marginTop: '3em' }}>
    <div className='bullet-margin-image' style={{ width: '100px' }}>
      <img src={tridentCrown} width='100px' />
    </div>
    <div className='bullet-content' style={{ flex: 1, paddingTop: '1em' }}>
      {children}
    </div>
  </div>
)

const RoadmapStep = ({
  header,
  number,
  children
}: {
  header: string
  number: number
  children: JSX.Element | JSX.Element[]
}) => (
  <BulletPoint>
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <Typography
            style={{
              textAlign: 'left',
              color: paleYellow,
              marginTop: '0',
              fontSize: '24px',
              minWidth: '100px',
              fontFamily: fontPhosphateSolid,
              padding: 0,
              textTransform: 'uppercase'
            }}
          >
            Phase {number}
          </Typography>
        </div>
        <div className='flex-column'>
          <Typography
            style={{
              textAlign: 'left',
              color: headerYellow,
              marginTop: '0',
              // marginLeft: '1em',
              fontSize: '24px',
              fontFamily: fontPhosphateSolid,
              padding: 0,
              textTransform: 'uppercase'
            }}
          >
            {header}
          </Typography>
        </div>
      </div>
      {children}
    </>
  </BulletPoint>
)

const RoadmapSection = () => (
  <section style={styles.darkBlueBackgroundSection}>
    <div className='section-content text-only' style={{ textAlign: 'left' }}>
      <Typography
        className='h5'
        variant='h5'
        align='center'
        style={{ fontSize: '2.5em' }}
        color={paleYellow}
        paragraph
        fontFamily={fontPhosphateSolid}
      >
        The Roadmap
      </Typography>
      <div>
        <RoadmapStep number={1} header='Rulers of Genezia: Early Access drop'>
          <p>
            NFTs of the three Rulers of Planet Genezia: Cleocatra, Purrseidon
            and Infurno will be available for purchase on OpenSea.
          </p>
        </RoadmapStep>
        <RoadmapStep
          number={2}
          header='The Tribes of Genezia Mint opens to Early Access members'
        >
          <p>
            The first five owners of each of the Early Access collection will
            get access to the Tribes of Genezia mint.
          </p>
        </RoadmapStep>
        <RoadmapStep
          number={3}
          header='The Tribes of Genezia Mint is available to the public'
        >
          <ul>
            <li>
              The first owners from the early access collection get airdropped
              hand-picked NFTs from the core team.
            </li>
            <li>
              {NUM_MINT} NFTs of the Tribes of Planet Genezia will be available
              for purchase on our website. Each idiosyncratic member of the
              tribe will unveil a unique combination of hand-crafted
              embellishments.
            </li>
            <li>
              The mint will also consist of an undisclosed number of 'Guardians
              of Planet Genezia' NFTs, randomly placed in the mint set. Lucky
              minters of Guardian NFTs are eligible for Ethereum drops, once the
              mint is completed.
            </li>
            <li>
              Verified Divine Feline NFT owners get access to exclusive roles
              and channels on Discord.
            </li>
          </ul>
        </RoadmapStep>
        <RoadmapStep number={4} header='Post-mint giveaways'>
          <ul>
            <li>
              The core team airdrops up to 1 ETH each to lucky owners of
              “Guardians of Planet Genezia” NFTs.
            </li>
            <li>
              The core team donates 10% of the proceeds from the mint to{' '}
              <a
                style={{ textDecoration: 'none', color: 'rgb(250, 218, 104)' }}
                href='https://www.internationalanimalrescue.org/'
              >
                International Animal Rescue
              </a>
              .
            </li>
          </ul>
        </RoadmapStep>
        <RoadmapStep number={5} header='Building the Divine Felines Tribe'>
          <ul>
            <li>
              The core team begins to collect ideas from Tribe members for the
              next mint. The collective voices of the tribe will shape the fate
              of Planet Genezia and the Divine Metaverse!
            </li>
            <li>
              Submitted ideas will be put to a vote. Tribe members that
              submitted winning ideas will be eligible for rewards such as
              royalties and giveaways.
            </li>
            <li>
              More giveaways to members of this community for supporting our
              project and taking a big step towards the betterment of animals in
              need.
            </li>
          </ul>
        </RoadmapStep>
        <RoadmapStep number={6} header='Onwards!'>
          <p>
            The core team begins work on the next mint, the first of many
            collaborative art projects by the Tribe and for the Tribe!
          </p>
        </RoadmapStep>
      </div>
    </div>
  </section>
)

const TeamSection = () => (
  <section style={styles.lightBlueBackgroundFlat}>
    <div className='section-content text-only'>
      <Typography
        className='h5'
        variant='h5'
        align='center'
        style={{ fontSize: '2.5em' }}
        color={headerYellow}
        paragraph
        fontFamily={fontPhosphateSolid}
      >
        The Team
      </Typography>
      <p>
        We love art. Tech is in our DNA. We know our shit and we have a plan.
        We’re friends from Carnegie Mellon University and UC Berkeley who have
        worked together for nearly a decade in art, design and technology, and
        have now set our sights on the Crypto world. We'd love for you to be a
        part of our crypto-art-metaverse journey!
      </p>
      <p>
        We're running this project with intention and integrity. We're here for
        the long haul - we want to build a strong community and long term value
        for Divine Felines asset holders.
      </p>
    </div>
  </section>
)

const FAQQuestion = ({
  question,
  children
}: {
  question: string
  children: JSX.Element | JSX.Element[]
}) => (
  <BulletPoint>
    <>
      <Typography
        style={{
          textAlign: 'left',
          color: paleYellow,
          marginTop: '0',
          fontSize: '24px',
          fontFamily: fontPhosphateSolid,
          padding: 0,
          textTransform: 'uppercase'
        }}
      >
        {question}
      </Typography>
      {children}
    </>
  </BulletPoint>
)

const FAQSection = () => (
  <section style={styles.purpleBackgroundSection}>
    <div className='section-content text-only' style={{ textAlign: 'left' }}>
      <Typography
        className='h5'
        variant='h5'
        align='center'
        style={{ fontSize: '2.5em' }}
        color={headerYellow}
        paragraph
        fontFamily={fontPhosphateSolid}
      >
        FAQs
      </Typography>
      <FAQQuestion question='Why should I buy a Divine Felines NFT?'>
        <p>
          Let's face it - you love cats (or are in denial), and you're just the
          right amount of weird to own one of our dope NFTs.
        </p>
        <p>
          We want to empower the community to make decisions for our future art
          projects and provide everyone an opportunity to get royalties for the
          art that is sold.
        </p>
        <p>
          We know the power of a strong community, and we know how to build one.
          Together we can help towards the betterment of animals, create art
          collaboratively and have some fun while we're at it!
        </p>
      </FAQQuestion>
      <FAQQuestion question='How do I buy a Divine Felines NFT?'>
        <p>It's really simple!</p>
        <p>
          All you need is an Ethereum wallet and a little bit of ETH. We
          recommend{' '}
          <a
            style={{ textDecoration: 'none', color: 'rgb(250, 218, 104)' }}
            href='https://metamask.io/'
          >
            Metamask
          </a>
          .
        </p>
        <p>
          Once you have your wallet set up with some ETH, you'll be able to buy
          a Divine Felines NFT either our website directly during the mint, or
          via secondary sales on OpenSea.
        </p>
      </FAQQuestion>
      <FAQQuestion question='How do I track where charitable donations go?'>
        <p>
          After the mint, we'll donate 10% of our earnings to charities related
          to animal welfare. We have a few charities in mind, and we'll look to
          the Divine Felines community on Discord to suggest additional options.
        </p>
        <p>
          Once the donation is completed, we'll upload all donation receipts as
          NFTs on the Divine Felines OpenSea collection, so that the transfer of
          funds is publicly verifiable and traceable.
        </p>
      </FAQQuestion>
      <FAQQuestion question='I am trying to create my own NFTs. Could you guide me?'>
        <p>
          Drop us an{' '}
          <a
            style={{ textDecoration: 'none', color: 'rgb(250, 218, 104)' }}
            href='mailto:divinefelines.nft@gmail.com'
          >
            email
          </a>
          , we'd love to be a part of your journey and share what we know!
        </p>
      </FAQQuestion>
    </div>
  </section>
)

const InterestFormSection = () => {
  const formName = 'interest-form'

  const [value, setValue] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [inputError, setInputError] = React.useState('')

  const encode = (data: Record<string, string>): string => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleSubmit: React.MouseEventHandler<HTMLAnchorElement> = ev => {
    ev.preventDefault()

    if (!EmailValidator.validate(value)) {
      setInputError("That doesn't look quite right.")
      return
    }
    setInputError('')

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        email: value
      })
    })
      .then(() => {
        setSuccess("You're all set! We'll be in touch.")
        setError('')
      })
      .catch(error => {
        setSuccess('')
        setError('Sorry! Looks like something went wrong.')
      })
      .finally(() => {
        setValue('')
        setTimeout(() => {
          setError('')
          setSuccess('')
        }, 10000)
      })
  }

  return (
    <section
      id='sign-up-form'
      style={{ ...styles.purpleBackgroundSection, paddingBottom: '1em' }}
    >
      <div className='section-content'>
        <Typography
          className='h5'
          variant='h5'
          align='center'
          color={headerYellow}
          paragraph
          fontFamily={fontPhosphate}
        >
          Stay in the know
        </Typography>
        <p>
          Sign up to receive mint details, release dates, early access to
          Discord, and more!
        </p>
        <form name={formName}>
          <TextField
            id='outlined-basic'
            label='Email'
            variant='outlined'
            value={value}
            error={!!inputError}
            helperText={inputError}
            onChange={e => {
              setInputError('')
              setValue(e.target.value)
            }}
            InputLabelProps={{
              sx: {
                // set the color of the label when not shrinked
                color: '#000',
                [`&.${inputLabelClasses.shrink}`]: {
                  // set the color of the label when shrinked (usually when the TextField is focused)
                  color: '#333',
                  backgroundColor: 'white',
                  borderRadius: 1,
                  paddingLeft: '5px',
                  paddingRight: '5px'
                }
              }
            }}
            InputProps={{ sx: { backgroundColor: 'white' } }}
          />
          <a
            href='#'
            style={{
              ...styles.yellowButton,
              height: '55px',
              paddingTop: '20px',
              marginTop: '1px',
              marginLeft: '5px'
            }}
            onClick={handleSubmit}
          >
            Sign up
          </a>
        </form>
        {(success || error) && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                marginTop: '1em',
                backgroundColor: 'rgba(114,233,65,50%)',
                borderRadius: '1em',
                padding: '1em',
                paddingLeft: '2em',
                paddingRight: '2em'
              }}
            >
              {success && <div className='success'>{success}</div>}
              {error && <div className='error'>{error}</div>}
            </div>
          </div>
        )}
        <h3
          style={{
            color: headerYellow,
            marginTop: '2em',
            fontSize: '24px',
            fontFamily: fontPhosphateSolid,
            padding: 0,
            textTransform: 'uppercase'
          }}
        >
          Follow us on social media
        </h3>
        <SocialLinks />
      </div>
    </section>
  )
}

const SocialLinks = () => (
  <div
    style={{
      margin: '2em',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center'
    }}
  >
    <div
      className='social-logo-container'
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '500px'
      }}
    >
      <a
        href='https://linktr.ee/divinefelines'
        className='social-logo'
        style={{
          background: `url(${linkTreeLogo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <a
        href='https://www.instagram.com/divinefelinesofficial/'
        className='social-logo'
        style={{
          background: `url(${instagramLogo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <a
        href='https://twitter.com/divine_felines'
        className='social-logo'
        style={{
          background: `url(${twitterLogo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      />
    </div>
  </div>
)

const SocialProfilesSection = () => (
  <section style={styles.darkBlueBackgroundSection}>
    <div className='section-content'>
      <Typography
        style={{
          color: headerYellow,
          marginTop: '0',
          fontSize: '24px',
          fontFamily: fontPhosphateSolid,
          padding: 0,
          textTransform: 'uppercase'
        }}
      >
        Follow us for more cool shit
      </Typography>
      <SocialLinks />
    </div>
  </section>
)

export default function App () {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ color: 'white' }}>
        <CssBaseline />
        <TitleImageSection />
        <DivineMetaverseIntroSection />
        <EarlyAccessSection />
        <MintPreviewSection />
        <InterestFormSection />
        <RoadmapSection />
        <TeamSection />
        <FAQSection />
        <SocialProfilesSection />
      </div>
    </ThemeProvider>
  )
}
